import type {
  CLSMetric,
  FCPMetric,
  FIDMetric,
  INPMetric,
  LCPMetric,
  Metric,
  TTFBMetric,
} from "web-vitals";

type WebVital = {
  critical?: boolean;
  // abbreviation
  name: string;
  // threshold in ms
  target: number;
  // full name
  label: string;
  // pulled from lighthouse
  description: string;
  // note about the metric, eg when will it change
  note?: string;
  // hint about why the metric may not have been measured
  hint?: string;
  url: string;
  sourceInfo: (metric: Metric) => unknown[];
};

type PerformanceMetricType = FCPMetric | FIDMetric | INPMetric | TTFBMetric;

// list the metrics that we want to warn about
export const criticalMetrics = ["CLS"];

export const CLS_THRESHOLD = 0.03;

const performanceSourceInfo = (metric: PerformanceMetricType) =>
  metric.entries.map((entry: PerformanceEntry) => entry.toJSON());

const hintMissedTiming =
  "Web Vitals may have missed timing for this metric. Please try reloading " +
  "the page.";
const inputHint =
  "User input is required to measure this metric. Please try interacting " +
  "with the page.";
const constantNote =
  "This metric is constant and will not change once the page is loaded.";

export const WEB_VITALS: Record<string, WebVital> = {
  CLS: {
    critical: true,
    name: "CLS",
    target: 0.1,
    label: "Cumulative Layout Shift",
    description:
      "Cumulative Layout Shift measures the movement of visible elements " +
      "within the viewport.",
    note:
      "CLS is a cumulative value, and is subject to increase as elements " +
      "shift on the page.",
    hint: hintMissedTiming,
    url: "https://web.dev/cls/",
    sourceInfo: (cls: CLSMetric) =>
      cls.entries
        .flatMap((entry) => entry.sources)
        .map((source) => source.node),
  },
  FCP: {
    name: "FCP",
    target: 1800,
    label: "First Contentful Paint",
    description:
      "First Contentful Paint marks the time at which the first text or " +
      "image is painted.",
    note: constantNote,
    hint: hintMissedTiming,
    url: "https://web.dev/fcp/",
    sourceInfo: performanceSourceInfo,
  },
  FID: {
    name: "FID",
    target: 100,
    label: "First Input Delay",
    description:
      "The maximum potential First Input Delay that your users could " +
      "experience is the duration of the longest task.",
    note: constantNote,
    hint: inputHint,
    url: "https://web.dev/fid/",
    sourceInfo: performanceSourceInfo,
  },
  INP: {
    name: "INP",
    target: 200,
    label: "Interaction to Next Paint",
    description:
      "Interaction to Next Paint measures page responsiveness, how long it " +
      "takes the page to visibly respond to user input.",
    note: "INP takes the highest input delay, this may change with user input",
    hint: inputHint,
    url: "https://web.dev/inp/",
    sourceInfo: performanceSourceInfo,
  },
  LCP: {
    name: "LCP",
    target: 2500,
    label: "Largest Contentful Paint",
    description:
      "Largest Contentful Paint marks the time at which the largest text or " +
      "image is painted.",
    note:
      "LCP takes the largest element, so this may change as elements are " +
      "added.",
    hint: hintMissedTiming,
    url: "https://web.dev/lcp/",
    sourceInfo: (lcp: LCPMetric) =>
      lcp.entries.flatMap((entry) =>
        [entry.url, entry.element].filter(Boolean),
      ),
  },
  TTFB: {
    name: "TTFB",
    target: 800,
    label: "Time to First Byte",
    description:
      "TTFB is a metric that measures the time between the request for a " +
      "resource and when the first byte of a response begins to arrive.",
    note: constantNote,
    hint: hintMissedTiming,
    url: "https://web.dev/ttfb/",
    sourceInfo: performanceSourceInfo,
  },
};
