import type { Metric } from "web-vitals/attribution";
import {
  onCLS,
  onFCP,
  onFID,
  onINP,
  onLCP,
  onTTFB,
} from "web-vitals/attribution";

import webVitalsEvents from "@/feature-web-vitals/events.mjs";

let listening: boolean, calledOnCLS: boolean;
export function listenWebVitals() {
  listening = true;
  if (calledOnCLS) return;
  [onCLS, onFCP, onFID, onINP, onLCP, onTTFB].forEach((fn) => {
    fn(
      (metric: Metric) => {
        if (!listening) return;
        webVitalsEvents.emit(webVitalsEvents.METRIC, metric);
      },
      { reportAllChanges: true },
    );
  });

  return () => {
    listening = false;
  };
}
