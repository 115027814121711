import type { Metric } from "web-vitals";

import { WEB_VITALS } from "@/feature-web-vitals/constants.mjs";

export function getSourceInfo(metric: Metric) {
  return WEB_VITALS[metric.name].sourceInfo(metric);
}

export function displayPercent(number: number) {
  return number.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
}
