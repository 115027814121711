import EventEmitter from "event-lite";
import type { Metric } from "web-vitals";

import router from "@/__main__/router.mjs";
import {
  CLS_THRESHOLD,
  criticalMetrics,
  WEB_VITALS,
} from "@/feature-web-vitals/constants.mjs";
import { devDebug } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

const webVitalsEvents = Object.assign(new EventEmitter(), {
  METRIC: "metric",
});

export const webVitalsState: Record<string, Metric> = Object.keys(
  WEB_VITALS,
).reduce((acc, key) => Object.assign(acc, { [key]: null }), {});

webVitalsEvents.on(webVitalsEvents.METRIC, (metric: Metric) => {
  const { name } = metric;
  if (criticalMetrics.includes(name)) {
    devDebug("CLS update", metric);

    if (metric.delta < CLS_THRESHOLD) return;

    // Highlight problems...
    for (const entry of metric.entries) {
      // @ts-ignore
      for (const source of entry.sources) {
        const { node } = source;
        if (!(node instanceof HTMLElement)) continue;
        node.dataset.cls = "true";
        node.style.outline = "var(--sp-0_5) dashed var(--red)";
        node.style.boxShadow = "0 0 var(--sp-4) var(--red) inset";
      }
    }
  }
  webVitalsState[name] = metric;
});

export function resetElements() {
  const clsElements = globals.document.querySelectorAll("[data-cls]");
  for (const element of clsElements) {
    if (!(element instanceof HTMLElement)) continue;
    element.style.outline = "";
    element.style.boxShadow = "";
    delete element.dataset.cls;
  }
}

// To prevent persistent annoyance (even though CLS is CUMULATIVE)
// Reset on route component change.
router.events.on(router.events.EVENT_CHANGE_ROUTE_COMPONENT, resetElements);

export default webVitalsEvents;
