import mainRefs from "@/__main__/refs.mjs";
import { resetElements } from "@/feature-web-vitals/events.mjs";
import { listenWebVitals } from "@/feature-web-vitals/web-vitals.mjs";
import { monitorWebVitals } from "@/feature-web-vitals/web-vitals-monitor.mjs";
import WebVitalsOverlay from "@/feature-web-vitals/WebVitalsOverlay.jsx";
import { pushClean } from "@/util/helpers.mjs";

const cleanup = [];

export function setup() {
  cleanup.push(listenWebVitals(), monitorWebVitals());

  (async () => {
    await mainRefs.didRender;

    cleanup.push(
      pushClean(mainRefs.components.floatingElementsTop, WebVitalsOverlay),
    );
  })();
}

export function teardown() {
  resetElements();
  cleanup.forEach((fn) => fn());
}
