import type { Metric } from "web-vitals";

import router from "@/__main__/router.mjs";
import { WEB_VITALS } from "@/feature-web-vitals/constants.mjs";
import webVitalsEvents, {
  webVitalsState,
} from "@/feature-web-vitals/events.mjs";
import { getSourceInfo } from "@/feature-web-vitals/util.mjs";
import { devDebug } from "@/util/dev.mjs";

const ENTRY_LIMIT = 10;
interface WebVitalsImpactEntry {
  delta: number;
  entries: Metric["entries"];
  sourceInfo: ReturnType<typeof getSourceInfo>;
  pageInfo: string;
}
export const webVitalsImpacts: Record<string, WebVitalsImpactEntry[]> =
  Object.keys(WEB_VITALS).reduce(
    (acc, key) => Object.assign(acc, { [key]: [] }),
    {},
  );

const didWarn = {};
export function monitorWebVitals() {
  const listener = (metric: Metric) => {
    const pageInfo =
      ifString(router.route?.component) || router.route?.currentPath;
    const impactEntry: WebVitalsImpactEntry = {
      delta: metric.delta,
      entries: metric.entries,
      sourceInfo: getSourceInfo(metric),
      pageInfo,
    };

    webVitalsImpacts[metric.name].push(impactEntry);
    webVitalsImpacts[metric.name].sort((a, b) => b.delta - a.delta);

    webVitalsImpacts[metric.name].length = Math.min(
      webVitalsImpacts[metric.name].length,
      ENTRY_LIMIT,
    );

    // warn if critical metric is subpar
    if (!WEB_VITALS[metric.name].critical) return;
    if (metric.rating === "good") return;
    if (didWarn[metric.name] === metric.rating) return;

    devDebug(`Web Vitals: ${metric.name} - ${metric.rating}`, {
      metric: metric.name,
      rating: metric.rating,
      value: metric.value,
      pageInfo,
    });
    didWarn[metric.name] = metric.rating;
  };
  webVitalsEvents.on(webVitalsEvents.METRIC, listener);
  return () => webVitalsEvents.off(webVitalsEvents.METRIC, listener);
}

export function summarizeWebVitalsImpact(name: string) {
  const impacts = webVitalsImpacts[name];
  const state = webVitalsState[name];

  const report = new Map<unknown, { pageInfo?: string; impact: number }>();

  if (state) {
    impacts.forEach((entry) =>
      entry.sourceInfo.forEach((e, _, sourceInfo) => {
        if (!report.has(e)) report.set(e, { impact: 0 });
        const val = report.get(e);
        val.impact += entry.delta / state.value / sourceInfo.length;
        val.pageInfo ||= entry.pageInfo;
      }),
    );
  }

  return Object.assign(
    Array.from(report.entries())
      .sort(([_, va], [__, vb]) => vb.impact - va.impact)
      .map(([k, v]) => ({
        sourceInfo: k,
        webVitalsPercent: v,
      })),
    { raw: impacts },
  );
}

function ifString(v: unknown): string | undefined {
  return typeof v === "string" ? v : undefined;
}
