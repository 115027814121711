import React from "react";
import { keyframes, styled } from "goober";

import {
  criticalMetrics,
  WEB_VITALS,
} from "@/feature-web-vitals/constants.mjs";
import webVitalsEvents, {
  webVitalsState,
} from "@/feature-web-vitals/events.mjs";
import { displayPercent } from "@/feature-web-vitals/util.mjs";
import { summarizeWebVitalsImpact } from "@/feature-web-vitals/web-vitals-monitor.mjs";
import IconCheck from "@/inline-assets/check-small.svg";
import Minimize from "@/inline-assets/minimize.svg";
import IconError from "@/inline-assets/Warning.svg";
import IconWarning from "@/inline-assets/warning-sign.svg";
import { classNames } from "@/util/class-names.mjs";

const ratings = ["good", "needs-improvement", "poor"];
const StatusIcons = {
  good: IconCheck,
  "needs-improvement": IconWarning,
  poor: IconError,
};

export default function WebVitalsOverlay() {
  const [vitals, setVitals] = React.useState(webVitalsState);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    webVitalsEvents.on(webVitalsEvents.METRIC, () => {
      setVitals({ ...webVitalsState });
    });
  }, [vitals]);

  const containerRef = React.useRef<HTMLDivElement>(null);

  if (!show) {
    const worstRating = criticalMetrics.reduce((worst, name) => {
      const metric = vitals[name];
      if (!metric) return worst;
      if (ratings.indexOf(metric.rating) > ratings.indexOf(worst)) {
        return metric.rating;
      }
      return worst;
    }, "good" as const);

    const StatusIcon = StatusIcons[worstRating];

    return (
      <OverlayContainer className={worstRating}>
        <button className="expand" onClick={() => setShow(true)}>
          <StatusIcon {...classNames(worstRating, "good-gray")} />
        </button>
      </OverlayContainer>
    );
  }

  return (
    <OverlayContainer ref={containerRef}>
      <div className="flex justify-between title type-caption--semi">
        {"Web Vitals"}
        <button className="minimize" onClick={() => setShow(false)}>
          <Minimize />
        </button>
      </div>

      <ol className="major vitals">
        {Object.entries(vitals).map(([name, data]) => {
          const vital = WEB_VITALS[name];

          const ratio = data ? data.value / vital.target : null;
          const ratioDisplay = data
            ? displayPercent(data.value / vital.target)
            : "---";

          return (
            <li
              key={`${name}:${data?.value}`}
              data-name={name}
              className={data?.rating}
              style={{
                "--ratio": ratioDisplay,
                "--bar-color": ratio >= 1 ? "red" : undefined,
              }}
              data-tip={vital.description}
            >
              <div
                onClick={() => {
                  /* eslint-disable no-console */
                  // We want to display this one in the console, bypassing
                  // devLog so that it shows up in prod logs for debugging
                  console.group(
                    `%c${name} - ${vital.label}\n` +
                      `%c* ${vital.note}\n` +
                      `%cLearn more: ${vital.url}`,
                    "font-size: 1.5em",
                    "font-size: 1em; font-style: italic; font-weight: 300; color: rgb(128,128,128);",
                    "font-size: 1em; font-style: italic; font-weight: 300; color: rgb(128,128,128);",
                  );
                  const report = summarizeWebVitalsImpact(name);
                  if (!report.length && !webVitalsState[name]) {
                    console.log(`${vital.hint}`);
                  }
                  if (!report.length) {
                    console.log(
                      `%cNo entries to report.`,
                      "font-style: italic; color: rgb(128,128,128);",
                    );
                    console.log();
                  }
                  for (const entry of report) {
                    const backgroundColor = getBackgroundColor(
                      entry["webVitalsPercent"].impact,
                    );
                    console.info(
                      "%c %s %s %o",
                      `
                      background: ${backgroundColor};
                      font-weight: 700;
                      padding: 2px;
                      height: 100vh;
                      border-radius: 5px;
                      text-shadow: 1px 1px 4px black;
                      `,
                      displayPercent(entry["webVitalsPercent"].impact),
                      entry["webVitalsPercent"].pageInfo,
                      entry.sourceInfo || "missing attribution",
                    );
                  }
                  console.groupEnd();
                  /* eslint-enable no-console */
                }}
              >
                <div className={`type-caption--semi metric-name flex gap-sp-1`}>
                  <span>{name}</span>
                  <span>{ratioDisplay}</span>
                </div>
                <div className={`type-caption--semi ${data?.rating} light`}>
                  <span>
                    {data ? Math.round(data?.value * 1000) / 1000 : "---"}
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    </OverlayContainer>
  );
}

const pulse = () => keyframes`
  from {
    background: var(--shade0-15);
  }
  to {
    background: transparent;
  }
`;

const OverlayContainer = styled("div", React.forwardRef)`
  position: fixed;
  padding: var(--sp-3);
  right: var(--sp-2);
  bottom: var(--sp-2);
  z-index: 998;

  display: flex;
  flex-direction: column;
  gap: var(--sp-2);

  background: var(--shade7);
  color: var(--shade3);
  border-radius: var(--br);
  outline: 1px solid var(--shade5);
  box-shadow: 0 0 10px black;

  button {
    all: unset;
    cursor: pointer;
  }
  .expand {
    width: var(--sp-6);
    height: var(--sp-6);
  }

  .minimize {
    svg {
      stroke: currentColor;
      stroke-width: 1px;
      fill: currentColor;
    }
  }

  .title {
    color: var(--shade2);
  }
  .good {
    color: var(--green);
  }
  .good-gray {
    color: var(--shade2-25);
  }
  svg {
    width: 100%;
    height: 100%;
  }

  .needs-improvement {
    color: var(--yellow);
  }
  .poor {
    color: var(--red);
  }

  .light {
    font-weight: 300;
  }

  .vitals {
    display: flex;
    flex-direction: column;
    gap: var(--sp-1);
  }

  .vitals > * {
    animation: ${pulse()} 1s ease-in-out forwards;
  }
  .vitals li {
    pointer-events: none;
  }
  .vitals li > * {
    position: relative;
    padding: var(--sp-0_5) var(--sp-2);

    display: flex;
    gap: var(--sp-4);
    justify-content: space-between;

    cursor: pointer;
    border-radius: var(--br-sm);
    border: 1px solid var(--shade3);

    pointer-events: auto;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: min(100%, var(--ratio, 0%));
      z-index: -1;
      background: var(--bar-color, white);
      opacity: 0.1;

      transition: width var(--transition);
    }
  }
`;

function getBackgroundColor(percent: number) {
  if (percent >= 1) return "transparent";

  const r = floatToInt(1);
  const g = floatToInt(1 - percent * 4);
  return `rgba(${r}, ${g}, 0, ${clamp(percent * 4, 0, 0.8)})`;
}

function floatToInt(value: number) {
  return clamp(Math.round(value * 255), 0, 255);
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
